























































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Resource, ResourceActions, ResourceGetters } from "./types";
import { humanFileSize } from "@/helpers";
import { UPMAN_BASE_URL } from "@/helpers/envConstants";
import {
  Broadcast,
  BroadcastActions,
  BroadcastGetters,
} from "../Broadcast/broadcast/types";
import ConfirmDialog from "../ConfirmDialog.vue";
import { User, CoreUserGetters } from "@/spect8-core-vue/src/types";

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class ResourcesList extends Vue {
  @Getter(BroadcastGetters.AllBroadcasts)
  readonly allBroadcasts!: Broadcast[];

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  @Getter(ResourceGetters.Resources)
  readonly resources!: Resource[];

  @Getter(CoreUserGetters.User)
  readonly user!: User | null;

  @Action(ResourceActions.SetResources)
  fetchResources!: () => Promise<void>;

  @Action(ResourceActions.DeleteResource)
  deleteResourceAction!: (resourceId: string) => Promise<void>;

  @Action(BroadcastActions.GetAllBroadcasts)
  getAllBroadcasts!: () => Promise<void>;

  confirmDelete = false;
  toBeDeleted: Resource | null = null;
  search = "";
  page = 0;
  perPage = 20;

  headers = [
    { text: this.$t("resources.file"), value: "title", width: "50%" },
    { text: this.$t("resources.broadcastLabel"), value: "broadcastIds" },
    { text: this.$t("resources.type"), value: "upManMedia.fileExtension" },
    { text: this.$t("resources.size"), value: "upManMedia.fileSize" },
    {
      text: this.$t("polls.actions"),
      value: "actions",
      sortable: false,
      width: "100px",
      align: "center",
      cellClass: "text-no-wrap",
    },
  ];
  expanded = [];

  filesize(size: number) {
    return humanFileSize(size);
  }

  openResource(resource: Resource) {
    const url = UPMAN_BASE_URL + resource.upManMedia.publicPath;
    window.open(url, "_blank");
  }

  get publicBaseUrl() {
    return UPMAN_BASE_URL;
  }

  async mounted() {
    await this.fetchResources();
    if (!this.allBroadcasts) {
      await this.getAllBroadcasts();
    }
  }

  handleDelete(p: Resource) {
    this.toBeDeleted = p;
    this.confirmDelete = true;
  }

  deleteResource(p: Resource | null) {
    if (!p) return;
    this.confirmDelete = false;
    this.toBeDeleted = null;
    this.deleteResourceAction(p.id).then(() => {
      this.$toast.success(this.$i18n.t("deleted.resource"));
    });
  }

  resourceBroadcastName(broadcastId: string): string {
    const broadcast = this.allBroadcasts?.find(
      (broadcast) => broadcast.id === broadcastId
    );
    return broadcast ? broadcast.broadcastName : "";
  }

  resourceDeletable(resource: Resource): boolean {
    return !(
      !!this.user &&
      this.user.isOnlyBroadcastModerator() &&
      (resource.broadcastIds === null ||
        resource.broadcastIds.some((id) =>
          this.broadcasts.some((b) => b.id !== id)
        ))
    );
  }
}
