











import ResourcesList from "@/components/Resources/ResourcesList.vue";
import ResourcesForm from "@/components/Resources/ResourcesForm.vue";
import PageTemplate from "@/components/PageTemplate.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    ResourcesList,
    ResourcesForm,
  },
})
export default class Resources extends Vue {
  RouteName = RouteName;
}
