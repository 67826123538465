











































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ConfirmDialog extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop({ default: 550 }) maxWidth!: number;

  @Prop({ required: false }) title!: string;
  @Prop({ default: null }) cancelText!: string | null;
  @Prop({ default: null }) confirmText!: string | null;
  @Prop({ default: "brand" }) confirmColor!: string | null;
  @Prop({ default: "tertiary" }) cancelColor!: string | null;

  @Prop({ default: false }) confirmDisabled!: boolean;
  @Prop({ default: false }) text!: boolean;
  @Prop({ default: false }) empty!: boolean;

  get cancelBtnText(): string {
    return this.cancelText ?? this.$t("Cancel").toString();
  }

  get confirmBtnText(): string {
    return this.confirmText ?? this.$t("Confirm").toString();
  }
}
