




































































import {
  UpManFileUploadDto,
  UpManUseCase,
} from "@/services/api/upman.service.types";
import {
  CoreConfigGetters,
  Role,
  TenantConfig,
  User,
  CoreUserGetters,
} from "@/spect8-core-vue/src/types";
import { VForm } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import {
  ResourceActions,
  ResourceCreateActionPayload,
  ResourceUploadCreateActionPayload,
} from "./types";

@Component({})
export default class ResourcesForm extends Vue {
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  @Action(ResourceActions.CreateResource)
  createResource!: (resource: ResourceCreateActionPayload) => Promise<void>;
  @Action(ResourceActions.UploadAndCreateResource)
  uploadAndCreateResource!: (
    resource: ResourceUploadCreateActionPayload
  ) => Promise<void>;

  @Getter(CoreUserGetters.User)
  readonly user!: User | null;

  @Getter(CoreConfigGetters.TenantConfig)
  readonly tenantConfig!: TenantConfig;

  Role = Role;

  selectedBroadcasts: string[] = [];
  valid = true;
  file: File | null = null;
  upFile: UpManFileUploadDto | null = null;
  activeAlways = false;
  uploading = false;

  $refs!: {
    form: VForm;
    fileInput: VForm;
  };

  created() {
    this.activeAlways = this.user
      ? !this.user.is(Role.BROADCAST_MODERATOR)
      : true;
  }

  get bcRules() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return [(v: any) => this.activeAlways || (v || []).length > 0];
  }

  async submit() {
    if (!this.$refs.form?.validate() || !this.file) return;

    try {
      const f: UpManFileUploadDto = {
        tenantId: this.tenantConfig.tenantId,
        usecase: UpManUseCase.Storefile,
        nfile: this.file,
        entityId: "",
        isDownloadable: true,
      };

      this.uploading = true;

      if (this.activeAlways || !this.selectedBroadcasts.length) {
        await this.uploadAndCreateResource({
          file: f,
        });
      } else {
        await Promise.all(
          this.selectedBroadcasts.map((bc) =>
            this.uploadAndCreateResource({
              file: { ...f, entityId: bc },
              broadcastId: bc,
            })
          )
        );
      }

      this.$toast.success(this.$i18n.t("created.resource"));
      this.initialData();
    } catch (error) {
      this.$toast.error(this.$i18n.t("resource.uploadError"));
    } finally {
      this.uploading = false;
    }
  }

  validateField() {
    this.$refs.form?.validate();
  }

  initialData() {
    this.$refs.form?.resetValidation();

    this.file = null;
    this.selectedBroadcasts = [];
  }

  beforeDestroy() {
    this.initialData();
  }
}
