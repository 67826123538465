var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"page-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"background",staticStyle:{"width":"100%"},attrs:{"footer-props":{
        'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
      },"headers":_vm.headers,"items":_vm.resources,"search":_vm.search,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("resources.listTitle"))+" ("+_vm._s(_vm.resources.length)+") ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('forms.search'),"single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"href":_vm.publicBaseUrl + item.upManMedia.publicPath,"target":"_blank"}},[_vm._v(_vm._s(item.title))])]}},{key:"item.upManMedia.fileExtension",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.upManMedia.fileExtension.slice(1).toUpperCase())+" ")]}},{key:"item.upManMedia.fileSize",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.filesize(item.upManMedia.fileSize))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.resourceDeletable(item))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.handleDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.broadcastIds",fn:function(ref){
      var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[(item.broadcastIds && item.broadcastIds.length)?_c('span',[_vm._v(_vm._s(_vm.resourceBroadcastName(item.broadcastIds[0])))]):_c('span',[_vm._v("N/A")])])]}}],null,true)})],1),_c('ConfirmDialog',{attrs:{"dialog":_vm.confirmDelete,"empty":true,"confirmText":_vm.$t('Delete'),"confirmColor":"error"},on:{"cancelled":function($event){_vm.confirmDelete = false},"confirmed":function($event){return _vm.deleteResource(_vm.toBeDeleted)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"h5 pt-0"},[_vm._v(" "+_vm._s(_vm.$t("dialogDeleteTitle", { item: _vm.toBeDeleted ? ("\"" + (_vm.toBeDeleted.title) + "\"") : "", }))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }